// Imagens
import participantes from "../../assets/images/edicoes/participantes.png"
import cidades from "../../assets/images/edicoes/cidades.png"
import regioes from "../../assets/images/edicoes/regioes.png"
import jogos from "../../assets/images/edicoes/jogos.png"
import finalistas from "../../assets/images/edicoes/winners.svg"
import map from "../../assets/images/edicoes/2020/map.jpg"

import doNotDisturb from '../../assets/images/edicoes/2020/doNotDisturb.png';
import gemtle from '../../assets/images/edicoes/2020/gemtle.png';
import anahi from '../../assets/images/edicoes/2020/anahi.png';
import amazonCriesForFreedom from '../../assets/images/edicoes/2020/amazonCriesForFreedom.png';
import brightSoul from '../../assets/images/edicoes/2020/brightSoul.png';
import darkestHour from '../../assets/images/edicoes/2020/darkestHour.png';
import hasinala from '../../assets/images/edicoes/2020/hasinala.png';
import haveAGoodOne from '../../assets/images/edicoes/2020/haveAGoodOne.png';
import midnigthCipher from '../../assets/images/edicoes/2020/midnigthCipher.png';
import superGrapplingGecko from '../../assets/images/edicoes/2020/superGrapplingGecko.png';
import swipecaster from '../../assets/images/edicoes/2020/swipecaster.png';
import techsorcist from '../../assets/images/edicoes/2020/techsorcist.png';
import tecnotupa from '../../assets/images/edicoes/2020/tecnotupa.png';


const ano20 = {
  ganhadoresControl: true,
  fotosControl: false,
  casesControl: false,
  fotos: [],
  mapa: map,
  PT: {
    ganhadoresTitle: 'Ganhadores da edição 20/21',
    // ganhadoresText: 'Clique no botão abaixo para ver a lista de finalistas da GameJam+20',
    // ganhadoresButton: 'Veja os finalistas!',
    ganhadores: [
      {
        nome: "Do Not Disturb",
        categoria: ['Jogo do ano', 'Melhor Narrativa'],
        img: doNotDisturb
      }, {
        nome: "Gemtle",
        categoria: ['Melhor Web Game', 'Melhor Mobile Game', 'Melhor Sound Design'],
        img: gemtle
      }, {
        nome: "Bright Soul",
        categoria: ['Melhor Game Design'],
        img: brightSoul
      }, {
        nome: "Swipecaster",
        categoria: ['Melhor Pitch', 'Melhor Game Ásia'],
        img: swipecaster
      }, {
        nome: "Super Grappling Gecko",
        categoria: ['Melhor Direção de arte'],
        img: superGrapplingGecko
      }, {
        nome: "Darkest Hour",
        categoria: ['Melhor Game Impacto Social'],
        img: darkestHour
      }, {
        nome: "Have A Good One",
        categoria: ['Melhor Game Impacto Social'],
        img: haveAGoodOne
      }, {
        nome: "Tecno Tupã",
        categoria: ['Melhor Game Educacional'],
        img: tecnotupa
      },
      {
        nome: "Amazon Cries For Freedom",
        categoria: ['Melhor Game Diversidade'],
        img: amazonCriesForFreedom
      },
      {
        nome: "Anahi",
        categoria: ['Melhor Game Diversidade'],
        img: anahi
      },
      {
        nome: "Techsorcist",
        categoria: ['Melhor Game LATAM'],
        img: techsorcist
      },
      {
        nome: "Midnight Cipher",
        categoria: ['Melhor Game América do Norte, Europa e Israel'],
        img: midnigthCipher
      },
      {
        nome: "Hasinala, on a Journey",
        categoria: ['Melhor Game África'],
        img: hasinala
      },
    ],
    momentosTitle: 'Melhores Momentos da Edição',
    cidadesTitle: 'Cidades Participantes',
    dados: {
      participantes: {
        img: participantes,
        title: 'participantes',
        data: '1100'
      },
      cidades: {
        img: cidades,
        title: 'cidades',
        data: '60'
      },
      regioes: {
        img: regioes,
        title: 'Países',
        data: '22'
      },
      jogos: {
        img: jogos,
        title: 'jogos',
        data: '281'
      },
      finalistas: {
        img: finalistas,
        title: 'Finalistas',
        data: '92'
    }
    },
    cases: {
      jogos: []
    }
  },
  EN: {
    ganhadoresTitle: 'Winners of the 20/21 edition',
    // ganhadoresText: 'Click on the button below to check the list of the GameJam+2020 finalists',
    // ganhadoresButton: 'Check the finalists!',
    ganhadores: [
      {
        nome: "Do Not Disturb",
        categoria: ['Best Game', 'Best narrative'],
        img: doNotDisturb
      }, {
        nome: "Gemtle",
        categoria: ['Best Web Game', 'Best Mobile Game', 'Best Sound Design'],
        img: gemtle
      }, {
        nome: "Bright Soul",
        categoria: ['Best Game Design'],
        img: brightSoul
      }, {
        nome: "Swipecaster",
        categoria: ['Best Pitch', 'Best Asia Game'],
        img: swipecaster
      }, {
        nome: "Super Grappling Gecko",
        categoria: ['Best Art Direction'],
        img: superGrapplingGecko
      }, {
        nome: "Darkest Hour",
        categoria: ['Best Social Impact Game'],
        img: darkestHour
      }, {
        nome: "Have A Good One",
        categoria: ['Best Social Impact Game'],
        img: haveAGoodOne
      }, {
        nome: "Tecno Tupã",
        categoria: ['Best Educational Game'],
        img: tecnotupa
      },
      {
        nome: "Amazon Cries For Freedom",
        categoria: ['Best Diversity Game'],
        img: amazonCriesForFreedom
      },
      {
        nome: "Anahi",
        categoria: ['Best Diversity Game'],
        img: anahi
      },
      {
        nome: "Techsorcist",
        categoria: ['Best LATAM Game'],
        img: techsorcist
      },
      {
        nome: "Midnight Cipher",
        categoria: ['Best Game North America, Europe and Israel'],
        img: midnigthCipher
      },
      {
        nome: "Hasinala, on a Journey",
        categoria: ['Best Africa Game'],
        img: hasinala
      },
    ],
    momentosTitle: 'Best Moments',
    cidadesTitle: 'Cities Confirmed',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participants',
        data: '1100'
      },
      cidades: {
        img: cidades,
        title: 'Cities',
        data: '60'
      },
      regioes: {
        img: regioes,
        title: 'Countries',
        data: '22'
      },
      jogos: {
        img: jogos,
        title: 'Games',
        data: '281'
      },
      finalistas: {
        img: finalistas,
        title: 'Finalists',
        data: '92'
      }
    },
    cases: {
      jogos: []
    }
  }
}

export default ano20