// Prêmios de 2018
import agathasEscape from '../../assets/images/edicoes/2018/agathasEscape.png';
import fungi from '../../assets/images/edicoes/2018/fungi.png';
import oneDollar from '../../assets/images/edicoes/2018/oneDollar.png';
import sailorSaviors from '../../assets/images/edicoes/2018/sailorSaviors.png';
import ghostein from '../../assets/images/edicoes/2018/ghostein.png';
import cuteArmy from '../../assets/images/edicoes/2018/cuteArmy.png';

// Cases de 2018
import patoCase from '../../assets/images/edicoes/2018/patoCase.png';
import sleightCase from '../../assets/images/edicoes/2018/sleightCase.png';
import teresinaCase from '../../assets/images/edicoes/2018/teresinaCase.png';
import littleCase from '../../assets/images/edicoes/2018/littleCase.png';
import nightCase from '../../assets/images/edicoes/2018/nightCase.png';
import madwayCase from '../../assets/images/edicoes/2018/madwayCase.png';

// Fotos de 2018
import f18_01 from '../../assets/images/edicoes/2018/01.jpg';
import f18_02 from '../../assets/images/edicoes/2018/02.jpg';
import f18_03 from '../../assets/images/edicoes/2018/03.jpg';
import f18_04 from '../../assets/images/edicoes/2018/04.jpg';
import f18_05 from '../../assets/images/edicoes/2018/05.jpg';
import f18_06 from '../../assets/images/edicoes/2018/06.jpg';
import f18_07 from '../../assets/images/edicoes/2018/07.jpg';
import f18_08 from '../../assets/images/edicoes/2018/08.jpg';
import f18_09 from '../../assets/images/edicoes/2018/09.jpg';
import f18_10 from '../../assets/images/edicoes/2018/10.jpg';
import f18_11 from '../../assets/images/edicoes/2018/11.jpg';
import f18_12 from '../../assets/images/edicoes/2018/12.jpg';
import f18_13 from '../../assets/images/edicoes/2018/13.jpg';
import f18_14 from '../../assets/images/edicoes/2018/14.jpg';
import f18_15 from '../../assets/images/edicoes/2018/15.jpg';
import f18_16 from '../../assets/images/edicoes/2018/16.jpg';
import f18_17 from '../../assets/images/edicoes/2018/17.jpg';
import f18_18 from '../../assets/images/edicoes/2018/18.jpg';
import f18_19 from '../../assets/images/edicoes/2018/19.jpg';
import f18_20 from '../../assets/images/edicoes/2018/20.jpg';
import f18_21 from '../../assets/images/edicoes/2018/21.jpg';
import f18_22 from '../../assets/images/edicoes/2018/22.jpg';
import f18_23 from '../../assets/images/edicoes/2018/23.jpg';

// Dados
import participantes from "../../assets/images/edicoes/participantes.png"
import cidades from "../../assets/images/edicoes/cidades.png"
import regioes from "../../assets/images/edicoes/regioes.png"
import jogos from "../../assets/images/edicoes/jogos.png"

//Mapa
import map18 from "../../assets/images/edicoes/2018/mapa.png"

const ano18 = {
  ganhadoresControl: true,
  fotosControl: true,
  casesControl: true,
  fotos: [f18_01, f18_02, f18_03, f18_04, f18_05, f18_06, f18_07, f18_08, f18_09, f18_10, f18_11, f18_12, f18_13, f18_14, f18_15, f18_16, f18_17, f18_18, f18_19, f18_20, f18_21, f18_22, f18_23],
  mapa: map18,
  PT: {
    ganhadoresTitle: 'Ganhadores da edição 2018',
    ganhadores: [
      {
        nome: "Agatha's escape",
        categoria: ['Melhor jogo Web pela Nuuvem', 'Melhor jogo Mobile pela TFG'],
        img: agathasEscape
      }, {
        nome: "Fungi",
        categoria: ['Prêmio Inovação'],
        img: fungi
      }, {
        nome: "One Dollar",
        categoria: ['Melhor jogo de impacto social pela UNICEF'],
        img: oneDollar
      }, {
        nome: "Sailor Saviors",
        categoria: ['1º Lugar por Voto Popular'],
        img: sailorSaviors
      }, {
        nome: "Ghostein",
        categoria: ['Melhor Narrativa'],
        img: ghostein
      }, {
        nome: "Cute Army",
        categoria: ['1º Lugar por Voto dos Jurados'],
        img: cuteArmy
      }
    ],
    momentosTitle: 'Melhores Momentos da Edição',
    cidadesTitle: 'Cidades Participantes',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participantes',
        data: '480'
      },
      cidades: {
        img: cidades,
        title: 'Cidades',
        data: '14'
      },
      regioes: {
        img: regioes,
        title: 'Regiões',
        data: '5'
      },
      jogos: {
        img: jogos,
        title: 'Jogos',
        data: '81'
      }
    },
    cases: {
      subtitulo: 'Clique sobre os cases para saber mais',
      jogos: [
        {
          nome: 'Patos de Minas',
          texto: 'Graças aos esforços de um empreendedor de Patos de Minas, Felipe Alvarenga, conseguiu transformar sua cidade na primeira sede da GJ+ fora das grandes capitais. Felipe, que já era organizador de eventos da área no município, ao organizar a sua etapa da GJ+ também estava organizando a primeira game jam da história da cidade, garantindo duas equipes para disputar a final no Rio de Janeiro',
          img: patoCase
        }, {
          nome: 'Sleight',
          texto: 'A equipe de Natal, Demerara, não poupou esforços em sua estratégia de marketing. Alugaram um stand na Brasil Game Show e se prepararam para realizar um soft-launch do seu jogo. Utilizando estratégias de merchandising e material promocional, criaram materiais físicos e aplicaram a uma dinâmica de integração entre os compradores no evento. Seus esforços não foram em vão, conseguiram fomentar o seu community building com mais de 150 vendas feitas durante o evento. E isso antes mesmo do jogo ter sido finalizado!',
          img: sleightCase
        }, {
          nome: 'Teresina',
          texto: 'O ecossistema de desenvolvimento de jogos do nordeste do país estava em um período de hibernação. Desenvolvedores do Piauí se sentiam isolados e sem perspectivas de mudanças rápidas. Pouco depois do fim da edição de 2018 da GJ+, Danilo e os outros envolvidos se reuniram e criaram o grupo PiauIndie. O primeiro passo para uma associação regional de desenvolvedores. Já se organizam para realizarem encontros e eventos em 2019, dentre eles, a Game Jam+ 2019.',
          img: teresinaCase
        }, {
          nome: 'Little Cheese Run',
          texto: 'Um grupo de amigos de Belo Horizonte resolveram participar da sua primeira jam como equipe, seguindo o tema regional como qualquer outra jam, mal sabiam eles que acabariam sendo selecionados como vencedores do voto popular regional. O grupo que começou com amigos da faculdade, arrumou emprego, fez parceria de negócios, rifou panela para ir até a final no Rio, ganhou patrocinador e criou um estúdio no meio desta incrível corrida e ainda diz que muito está por vir.',
          img: littleCase
        }, {
          nome: 'Night Claw',
          texto: 'Renan entrou na competição com a intenção de trabalhar junto com seu sócio Luan que estava em outro estado, porém as regras da GJ+ não permitem esse tipo de participação Mesmo assim Renan continuou, e não só desenvolveu sozinho o projeto como foi escolhido pelos jurados como finalista. Mais tarde nomeado como Night Claw, o jogo foi publicado pela equipe na plataforma do Newgrounds e foi selecionado como “jogo em destaque” exibido na página principal.',
          img: nightCase
        }, {
          nome: 'Madway to Heaven',
          texto: 'Os amigos brasilienses participaram de sua primeira game jam em 2014, a qual contava com mais de 800 equipes participantes, e se surpreenderam por seu jogo ficar entre os 100 melhores. Depois de vencerem o primeiro lugar em uma jam competitiva no mesmo ano, resolveram se unir de vez e formar o estúdio Madpixel. Após a GJ+  os membros da equipe se inscreveram no programa CDTank, e sendo a única equipe com um projeto de jogo na rodada e utilizando o mesmo pitch que os fez vencer na regional da GJ+, a equipe da Madpixel conseguiu ser uma das vencedoras. E captar um investimento de R$20mil.',
          img: madwayCase
        }
      ]
    }
  },
  EN: {
    ganhadoresTitle: 'Winners of the 2018 edition',
    ganhadores: [
      {
        nome: "Agatha's escape",
        categoria: ['Best Web Game by Nuuvem', 'Best Mobile Game by TFG'],
        img: agathasEscape
      }, {
        nome: "Fungi",
        categoria: ['Innovation Award'],
        img: fungi
      }, {
        nome: "One Dollar",
        categoria: ['Best Social Impact Game by UNICEF'],
        img: oneDollar
      }, {
        nome: "Sailor Saviors",
        categoria: ['1st Place by Popular Voting'],
        img: sailorSaviors
      }, {
        nome: "Ghostein",
        categoria: ['Best Narrative'],
        img: ghostein
      }, {
        nome: "Cute Army",
        categoria: ['1st Place Voted by Judges'],
        img: cuteArmy
      }
    ],
    momentosTitle: 'Best Moments',
    cidadesTitle: 'Cities Confirmed',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participants',
        data: '480'
      },
      cidades: {
        img: cidades,
        title: 'Cities',
        data: '14'
      },
      regioes: {
        img: regioes,
        title: 'Regions',
        data: '5'
      },
      jogos: {
        img: jogos,
        title: 'Games',
        data: '81'
      }
    },
    cases: {
      jogos: [
        {
          nome: 'Patos de Minas',
          texto: 'Because of the efforts of an enterpreneur from the city of Patos de Minas, Felipe Alvarenga managed to make his city into GJ+’s first regional jam site outside of Brazil’s state capitals. Felipe already was an local event organizer, and when he made his jam site a reality he managed to organize Patos de Minas’ first game jam ever.',
          img: patoCase
        }, {
          nome: 'Sleight',
          texto: 'Demerara’s team, from Natal, did not spare efforts in their marketing strategies. They got an exhibitor’s stand at Brasil Game Show and prepared themselves to make a soft-launch of their game. Utilizing different merchandising and promotional material strategies they created official game swag and created a interactive buying experience for their buyers. Their efforts were not wasted, they managed to create and foster their initial community with more than 150 sales during the event. And all of that before even finishing their game!',
          img: sleightCase
        }, {
          nome: 'Teresina',
          texto: 'The game developing ecosystem in Brazil’s north-eastern region was basically in a state of hibernation. The developers from Piauí were feeling isolated and with no perspective of change whatsoever. Shortly after the end of GJ+’s 2018 edition, Danilo and the other developers involved in their regional jam site organization got together and created the PiauIndie group. It is their first step to creating an oficial game developers regional association. They soon organized themselves to host more get togethers and events in 2019, and of course, GJ+ 2019 local jam site being one of them.',
          img: teresinaCase
        }, {
          nome: 'Little Cheese Run',
          texto: 'A group of friends from Belo Horizonte decided they wanted to finally participate in a game jam as a team, but they could hardly expect that it was their project the one selected as a finalist from the Online Popular Vote. The team that started only as friends getting together to jam suddenly started changing fast. They got jobs in another studio, made business partnerships, raffled a pan to aquire funds to go to Rio, got a sponsor and created their own studio all in the middle of developing the game. We can expect many more things from them from now on.',
          img: littleCase
        }, {
          nome: 'Night Claw',
          texto: 'Renan joined the competition intending to jam together with his associate Luan that was on another city at the moment. But as the rules of GJ+ didn’t allow for teams to work outside the jam site, they had to adapt. So Renan went through the first 48h working alone on the game and even managed to get picked as a finalist by the judges. Later, the game that was named “Night Claw” was published by the team at Newgrounds and was even featured on the main page.',
          img: nightCase
        }, {
          nome: 'Madway to Heaven',
          texto: 'This group of friends from Brasília participated together on their first game jam in 2014. That jam had more than 800 teams participating and they got really surprised when they managed to get a spot on the top 100. After they got first place in another game jam at the same year they decided to oficially get together and create the Madpixel Studio. After GJ+ the team members entered their game in the CDTank program. They were the only team there with a game project and utilized the same pitch that made them win at the regionals. Doing that they were selected as one of the winners and grab an R$20.000 investment.',
          img: madwayCase
        }
      ]
    }
  }
}

export default ano18