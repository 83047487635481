// Prêmios de AQGJ+
import nirvana from '../../assets/images/edicoes/aqgj/nirvana.jpg';
import sparkJoy from '../../assets/images/edicoes/aqgj/sparkJoy.jpg';
import charlie from '../../assets/images/edicoes/aqgj/charlie.jpg';
import insideYou from '../../assets/images/edicoes/aqgj/insideYou.jpg';
import isolatedRoom from '../../assets/images/edicoes/aqgj/isolatedRoom.jpg';
import zumbanho from '../../assets/images/edicoes/aqgj/zumbanho.jpg';

// Dados
import participantes from "../../assets/images/edicoes/participantes.png"
import cidades from "../../assets/images/edicoes/cidades.png"
import regioes from "../../assets/images/edicoes/regioes.png"
import jogos from "../../assets/images/edicoes/jogos.png"

//Mapa
import mapAQGJ from "../../assets/images/edicoes/aqgj/mapa.png"

const aqgj = {
  ganhadoresControl: true,
  fotosControl: false,
  casesControl: false,
  fotos: [],
  mapa: mapAQGJ,
  PT: {
    ganhadoresTitle: 'Ganhadores da edição AQGJ+',
    ganhadores: [
      {
        nome: "Nirvana",
        categoria: ['1° Lugar'],
        img: nirvana
      }, {
        nome: "Spark Joy",
        categoria: ['2° Lugar'],
        img: sparkJoy
      }, {
        nome: "Charlie's Adventures in the Heart Kingdom",
        categoria: ['3° Lugar'],
        img: charlie
      }, {
        nome: "Inside You",
        categoria: ['4° Lugar'],
        img: insideYou
      }, {
        nome: "Isolated Room",
        categoria: ['5° Lugar'],
        img: isolatedRoom
      }, {
        nome: "Zumbanho",
        categoria: ['6° Lugar'],
        img: zumbanho
      }
    ],
    momentosTitle: 'Melhores Momentos da Edição',
    cidadesTitle: 'Cidades Participantes',
    dados: {
      participantes: {
        img: participantes,
        title: 'participantes',
        data: '150'
      },
      cidades: {
        img: cidades,
        title: 'cidades',
        data: '22'
      },
      regioes: {
        img: regioes,
        title: 'países',
        data: '9'
      },
      jogos: {
        img: jogos,
        title: 'jogos',
        data: '33'
      }
    },
    cases: {
      jogos: []
    }
  },
  EN: {
    ganhadoresTitle: 'Winners of the AQGJ+ edition',
    ganhadores: [
      {
        nome: "Nirvana",
        categoria: ['1st Place'],
        img: nirvana
      }, {
        nome: "Spark Joy",
        categoria: ['2nd Place'],
        img: sparkJoy
      }, {
        nome: "Charlie's Adventures in the Heart Kingdom",
        categoria: ['3rd Place'],
        img: charlie
      }, {
        nome: "Inside You",
        categoria: ['4th Place'],
        img: insideYou
      }, {
        nome: "Isolated Room",
        categoria: ['5th Place'],
        img: isolatedRoom
      }, {
        nome: "Zumbanho",
        categoria: ['6th Place'],
        img: zumbanho
      }
    ],
    momentosTitle: 'Best Moments',
    cidadesTitle: 'Cities Confirmed',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participants',
        data: '150'
      },
      cidades: {
        img: cidades,
        title: 'Cities',
        data: '22'
      },
      regioes: {
        img: regioes,
        title: 'Countries',
        data: '9'
      },
      jogos: {
        img: jogos,
        title: 'Games',
        data: '33'
      }
    },
    cases: {
      jogo: []
    }
  }
}

export default aqgj