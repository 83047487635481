// Prêmios de 2017
import swordOfYohh from '../../assets/images/edicoes/2017/swordOfYohh.png';
import ovelhas from '../../assets/images/edicoes/2017/ovelhas.png';
import redScarf from '../../assets/images/edicoes/2017/redScarf.png';

// Cases de 2017
import swordOfYohhCase from '../../assets/images/edicoes/2017/swordOfYohhCase.png';
import ovelhasCase from '../../assets/images/edicoes/2017/ovelhasCase.png';

// Fotos de 2017
import f17_01 from '../../assets/images/edicoes/2017/01.jpg';
import f17_02 from '../../assets/images/edicoes/2017/02.jpg';
import f17_03 from '../../assets/images/edicoes/2017/03.jpg';
import f17_04 from '../../assets/images/edicoes/2017/04.jpg';
import f17_05 from '../../assets/images/edicoes/2017/05.jpg';
import f17_06 from '../../assets/images/edicoes/2017/06.jpg';
import f17_07 from '../../assets/images/edicoes/2017/07.jpg';
import f17_08 from '../../assets/images/edicoes/2017/08.jpg';
import f17_09 from '../../assets/images/edicoes/2017/09.jpg';
import f17_10 from '../../assets/images/edicoes/2017/10.jpg';
import f17_11 from '../../assets/images/edicoes/2017/11.jpg';
import f17_12 from '../../assets/images/edicoes/2017/12.jpg';
import f17_13 from '../../assets/images/edicoes/2017/13.jpg';
import f17_14 from '../../assets/images/edicoes/2017/14.jpg';
import f17_15 from '../../assets/images/edicoes/2017/15.jpg';
import f17_16 from '../../assets/images/edicoes/2017/16.jpg';
import f17_17 from '../../assets/images/edicoes/2017/17.jpg';
import f17_18 from '../../assets/images/edicoes/2017/18.jpg';

// Imagens
import participantes from "../../assets/images/edicoes/participantes.png"
import cidades from "../../assets/images/edicoes/cidades.png"
import regioes from "../../assets/images/edicoes/regioes.png"
import jogos from "../../assets/images/edicoes/jogos.png"
import map17 from "../../assets/images/edicoes/2017/mapa.png"


const ano17 = {
  ganhadoresControl: true,
  fotosControl: true,
  casesControl: true,
  fotos: [f17_01, f17_02, f17_03, f17_04, f17_05, f17_06, f17_07, f17_08, f17_09, f17_10, f17_11, f17_12, f17_13, f17_14, f17_15, f17_16, f17_17, f17_18],
  mapa: map17,
  PT: {
    ganhadoresTitle: 'Ganhadores da edição 2017',
    ganhadores: [
      {
        nome: "Sword of Yohh",
        categoria: ['Escolha dos jurados'],
        img: swordOfYohh
      }, {
        nome: "As Ovelhas Contra-Atacam",
        categoria: ['Voto popular'],
        img: ovelhas
      }, {
        nome: "Red Scarf",
        categoria: ['Prêmio Gamer Trials'],
        img: redScarf
      }
    ],
    momentosTitle: 'Melhores Momentos da Edição',
    cidadesTitle: 'Cidades Participantes',
    dados: {
      participantes: {
        img: participantes,
        title: 'participantes',
        data: '430'
      },
      cidades: {
        img: cidades,
        title: 'cidades',
        data: '6'
      },
      regioes: {
        img: regioes,
        title: 'regiões',
        data: '3'
      },
      jogos: {
        img: jogos,
        title: 'jogos',
        data: '62'
      }
    },
    cases: {
      jogos: [
        {
          nome: 'Sword of Yohh',
          texto: 'A equipe de São Paulo criou um jogo de battle arena com uma arte diferenciada e gameplay divertido. Após ganharem o voto dos jurados regional, logo levaram também a vitória nacional. Formaram a empresa Undevs e levaram o jogo para vários eventos cativando novos jogadores, inclusive na GDC 2018.',
          img: swordOfYohhCase
        }, {
          nome: 'Ovelhas Contra Atacam',
          texto: 'Equipe mineira, de Belo Horizonte, que se formou pela primeira vez durante a GJ+ criaram um jogo de temática inusitada que conquistou os votos do público na etapa regional e nacional. Mais tarde não só seguiram como equipe e se formalizaram como empresa, como também criaram um novo título quando passaram para a pré-aceleração da Playbor em Minas Gerais.',
          img: ovelhasCase
        }
      ]
    }
  },
  EN: {
    ganhadoresTitle: 'Winners of the 2017 edition',
    ganhadores: [
      {
        nome: "Sword of Yohh",
        categoria: ['1st Place Voted by Judges'],
        img: swordOfYohh
      }, {
        nome: "As Ovelhas Contra-Atacam",
        categoria: ['1st Place by Popular Voting'],
        img: ovelhas
      }, {
        nome: "Red Scarf",
        categoria: ['Gamer Trials Award'],
        img: redScarf
      }
    ],
    momentosTitle: 'Best Moments',
    cidadesTitle: 'Cities Confirmed',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participants',
        data: '430'
      },
      cidades: {
        img: cidades,
        title: 'Cities',
        data: '6'
      },
      regioes: {
        img: regioes,
        title: 'Regions',
        data: '3'
      },
      jogos: {
        img: jogos,
        title: 'Games',
        data: '62'
      }
    },
    cases: {
      jogos: [
        {
          nome: 'Sword of Yohh',
          texto: 'The team from São Paulo managed to develop a battle arena game with an exquisite art style and fun gameplay. After they won the juror’s approval in the regionals, they soon managed to get the national title as well. They got together and created the Undevs Studio, taking their game to many other events, winning new player’s hearts, even at GDC 2018.',
          img: swordOfYohhCase
        }, {
          nome: 'Ovelhas Contra Atacam',
          texto: 'The team from Belo Horizonte that first got together during GJ+ developed a game with an unusual theme, that got them the Popular Vote from both the regional and national stages. They stayed as a team after the event and created their studio and also started working on another title for Playbor’s pre-acceleration program.',
          img: ovelhasCase
        }
      ]
    }
  }
}

export default ano17