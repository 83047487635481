import React from 'react';
import styled from 'styled-components'

//Helpers
import Title from "../helpers/Title"

//Components
import Layout from "../components/Layout"
import Language from "../components/Language"
import Seo from "../components/seo"
import Ganhador from "../components/Ganhador"
import Gallery from "../components/Gallery"

// import texts from "../components/Edicoes/content"
import ano20 from "../components/Edicoes/ano20"
import aqgj from "../components/Edicoes/aqgj"
import ano19 from "../components/Edicoes/ano19"
import ano18 from "../components/Edicoes/ano18"
import ano17 from "../components/Edicoes/ano17"



const getEdition = () => {
  let url = undefined
  if (typeof window !== 'undefined') url = window.location.search.split('?')[1];

  if (url === "20/21") {
    return ano20
  } else if (url === "AQGJ+") {
    return aqgj
  } else if (url === "2019") {
    return ano19
  } else if (url === "2018") {
    return ano18
  } else if (url === "2017") {
    return ano17
  } else {
    return ano20
  }
}

const Edicoes = () => {
  const edition = Language(getEdition())

  return (
    <Layout>
      <Seo title="Edições" />
      {getEdition().ganhadoresControl ?
        (
          <Container>
            <Title>{edition.ganhadoresTitle}</Title>
            <Content>
              {edition.ganhadores.map(
                item => {
                  return (
                    <Ganhador
                      title={item.nome}
                      category={item.categoria}
                      img={item.img}
                    />
                  );
                }
              )}
            </Content>
          </Container>
        ) : (
          <Container>
            <Title>{edition.ganhadoresTitle}</Title>
            <Text>{edition.ganhadoresText}</Text>
            <Button
              href="https://docs.google.com/spreadsheets/d/1VRqP3wyd_OH3iMyP_7aNLJpIwegz7_By_9jnp-0Hdic/edit?usp=sharing"
              target='_BLANK'
              rel="noopener noreferrer"
            >
              {edition.ganhadoresButton}
            </Button>
          </Container>
        )
      }
      {getEdition().fotosControl ? (
        <Container>
          <Title>{edition.momentosTitle}</Title>
          <Gallery images={getEdition().fotos} />
        </Container>
      ) : null
      }
      <Container>
        <Title>{edition.cidadesTitle}</Title>
        <img src={getEdition().mapa} alt='' />
      </Container>
      <Container>
        <Content>
          <Card>
            <img src={edition.dados.participantes.img} alt='' />
            <CardData>{edition.dados.participantes.data}</CardData>
            <CardTitle>{edition.dados.participantes.title}</CardTitle>
          </Card>
          <Card>
            <img src={edition.dados.cidades.img} alt='' />
            <CardData>{edition.dados.cidades.data}</CardData>
            <CardTitle>{edition.dados.cidades.title}</CardTitle>
          </Card>
          <Card>
            <img src={edition.dados.regioes.img} alt='' />
            <CardData>{edition.dados.regioes.data}</CardData>
            <CardTitle>{edition.dados.regioes.title}</CardTitle>
          </Card>
          <Card>
            <img src={edition.dados.jogos.img} alt='' />
            <CardData>{edition.dados.jogos.data}</CardData>
            <CardTitle>{edition.dados.jogos.title}</CardTitle>
          </Card>
          {(typeof window !== 'undefined') ?
            window.location.search.split('?')[1] === '2020' ? (
              <Card>
                <img src={edition.dados.finalistas.img} alt='' />
                <CardData>{edition.dados.finalistas.data}</CardData>
                <CardTitle>{edition.dados.finalistas.title}</CardTitle>
              </Card>
            ) : null
            : null
          }
        </Content>
      </Container>
      {getEdition().casesControl ? (
        <Container>
          <Title>Cases</Title>
          <Content>
            {edition.cases.jogos.map(
              (item, index) => (
                <Case key={index}>
                  <img src={item.img} alt='' />
                  <CaseTitle>{item.nome}</CaseTitle>
                </Case>
              )
            )}
          </Content>
        </Container>
      ) : null
      }
    </Layout>
  )
}

export default Edicoes

const Container = styled.section`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 32px 0 0; */
`

const Card = styled.div`
  width: 220px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CardData = styled.div`
  margin: .5rem 0;
  font-family: var(--primary);
  font-size: 2.5rem;
  color: var(--blue);
`

const CardTitle = styled.div`
  font-family: var(--primaryLight);
  font-size: 1.5rem;
  color: var(--pink);
`

const Case = styled.div`
  width: 280px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CaseTitle = styled.p`
  margin: 1rem 0;
  font-family: var(--primary);
  font-size: 1.5rem;
  color: var(--blue);
  text-align: center;
`

const Text = styled.p`
  font-family: var(--primaryLight);
  font-size: 1.25rem;
  color: var(--pink);
  margin-bottom: 2rem;
`

const Button = styled.a`
  display: block;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border: none;
  border-radius: .5rem;
  background: #67a99a;
  font-family: var(--primary);
  font-size: 1rem;
  color: var(--white);
`