import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const Gallery = ({ images }) => (
    <Carousel
      showStatus={false}
      showThumbs={false}
      useKeyboardArrows={true}
      emulateTouch={true}
    >
      {images.map(
        item => {
          return (
            <div>
              <img src={item} alt='' />
            </div>
          );
        }
      )}
    </Carousel>
)

export default Gallery
