import React from 'react'
import styled from 'styled-components'

const Ganhador = ({ title, category, img }) => (
  <Container>
    <img src={img} alt='' />
    <Title>{title}</Title>
    {category.map(
      item => <Text>{item}</Text>
    )}
  </Container>
)

export default Ganhador

const Container = styled.div`
  width: 320px;
  margin: 0 12px 32px;

  @media screen and (max-width: 768px) {
    width: 300px;
    margin: 0 10px 32px;
  }
`

const Title = styled.h3`
  margin: 0 0 0 24px;
  font-family: var(--primary);
  color: var(--blue);

  @media screen and (max-width: 768px) {
    margin: 0 0 0 20px;
  }
`
const Text = styled.p`
  margin: 4px 24px;
  font-family: var(--primaryLight);
  color: var(--pink);

  &:last-child{
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 4px 0 4px 20px;
  }
`