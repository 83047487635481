// Prêmios
import ghostemOut from '../../assets/images/edicoes/2019/ghostemOut.png';
import decker from '../../assets/images/edicoes/2019/decker.png';
import chamaBailao from '../../assets/images/edicoes/2019/chamaBailao.png';
import timeBricks from '../../assets/images/edicoes/2019/timeBricks.png';
import superCatUp from '../../assets/images/edicoes/2019/superCatUp.png';
import blinkDaggerZ from '../../assets/images/edicoes/2019/blinkDaggerZ.png';
import oculto from '../../assets/images/edicoes/2019/oculto.png';

// Fotos
import f19_01 from '../../assets/images/edicoes/2019/01.jpg';
import f19_02 from '../../assets/images/edicoes/2019/02.jpg';
import f19_03 from '../../assets/images/edicoes/2019/03.jpg';
import f19_04 from '../../assets/images/edicoes/2019/04.jpg';
import f19_05 from '../../assets/images/edicoes/2019/05.jpg';
import f19_06 from '../../assets/images/edicoes/2019/06.jpg';
import f19_07 from '../../assets/images/edicoes/2019/07.jpg';
import f19_08 from '../../assets/images/edicoes/2019/08.jpg';
import f19_09 from '../../assets/images/edicoes/2019/09.jpg';
import f19_10 from '../../assets/images/edicoes/2019/10.jpg';
import f19_11 from '../../assets/images/edicoes/2019/11.jpg';
import f19_12 from '../../assets/images/edicoes/2019/12.jpg';
import f19_13 from '../../assets/images/edicoes/2019/13.jpg';
import f19_14 from '../../assets/images/edicoes/2019/14.jpg';
import f19_15 from '../../assets/images/edicoes/2019/15.jpg';
import f19_16 from '../../assets/images/edicoes/2019/16.jpg';
import f19_17 from '../../assets/images/edicoes/2019/17.jpg';

// Dados
import participantes from "../../assets/images/edicoes/participantes.png"
import cidades from "../../assets/images/edicoes/cidades.png"
import regioes from "../../assets/images/edicoes/regioes.png"
import jogos from "../../assets/images/edicoes/jogos.png"


// Mapa
import map19 from "../../assets/images/edicoes/2019/mapa.png"

const ano19 = {
  ganhadoresControl: true,
  fotosControl: true,
  casesControl: false,
  fotos: [f19_01, f19_02, f19_03, f19_04, f19_05, f19_06, f19_07, f19_08, f19_09, f19_10, f19_11, f19_12, f19_13, f19_14, f19_15, f19_16, f19_17],
  mapa: map19,
  PT: {
    ganhadoresTitle: 'Ganhadores da edição 2019',
    ganhadores: [
      {
        nome: "Ghost'em Out",
        categoria: ['Melhor Jogo', 'Melhor Jogabilidade'],
        img: ghostemOut
      }, {
        nome: "Decker",
        categoria: ['Melhor Narrativa', 'Melhor Visual'],
        img: decker
      }, {
        nome: "Chama! Bailão",
        categoria: ['Melhor Conceito', 'Melhor Som'],
        img: chamaBailao
      }, {
        nome: "Time Bricks",
        categoria: ['Vencedor Categoria Nuuvem'],
        img: timeBricks
      }, {
        nome: "Super Cat Up",
        categoria: ['Melhor Pitch', 'Vencedor Categoria Wildlife'],
        img: superCatUp
      }, {
        nome: "Blink Dagger Z",
        categoria: ['Melhor Jogo Estrangeiro'],
        img: blinkDaggerZ
      }, {
        nome: "O culto",
        categoria: ['Vencedor Categoria Dangen'],
        img: oculto
      }
    ],
    momentosTitle: 'Melhores Momentos da Edição',
    cidadesTitle: 'Cidades Participantes',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participantes',
        data: '800'
      },
      cidades: {
        img: cidades,
        title: 'Cidades',
        data: '33'
      },
      regioes: {
        img: regioes,
        title: 'Países',
        data: '8'
      },
      jogos: {
        img: jogos,
        title: 'Jogos',
        data: '197'
      }
    },
    cases: {
      subtitle: 'Clique sobre os cases para saber mais',
      jogos: [
      ]
    }
  },
  EN: {
    ganhadoresTitle: 'Winners of the 2019 edition',
    ganhadores: [
      {
        nome: "Ghost'em Out",
        categoria: ['Best Game - 1st Place', 'Best Gameplay'],
        img: ghostemOut
      }, {
        nome: "Decker",
        categoria: ['Best Narrative', 'Best Visual'],
        img: decker
      }, {
        nome: "Chama! Bailão",
        categoria: ['Best Concept', 'Best Sound'],
        img: chamaBailao
      }, {
        nome: "Time Bricks",
        categoria: ['Nuuvem Category Winner'],
        img: timeBricks
      }, {
        nome: "Super Cat Up",
        categoria: ['Best Pitch - 1st Place', 'Best Mobile Game'],
        img: superCatUp
      }, {
        nome: "Blink Dagger Z",
        categoria: ['Best Foreigner Game'],
        img: blinkDaggerZ
      }, {
        nome: "O culto",
        categoria: ['Dangen Entertainment Category Winner'],
        img: oculto
      }
    ],
    momentosTitle: 'Best Moments',
    cidadesTitle: 'Cities Confirmed',
    dados: {
      participantes: {
        img: participantes,
        title: 'Participants',
        data: '800'
      },
      cidades: {
        img: cidades,
        title: 'Cities',
        data: '33'
      },
      regioes: {
        img: regioes,
        title: 'Countries',
        data: '8'
      },
      jogos: {
        img: jogos,
        title: 'Games',
        data: '197'
      }
    },
    cases: {
      subtitle: 'Click on the cases to know more',
      jogos: [
      ]
    }
  }
}

export default ano19